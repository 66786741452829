.container {
  min-height: 60vh;
  margin-bottom: 5em;
}

.Logo img{
  max-width: 95%;    /* Reduz o tamanho da imagem para no máximo 50% da largura da tela */
  max-height: 95vh;  /* Limita a altura para não ficar muito grande */
  object-fit: contain; /* Mantém a proporção original da imagem */
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 5%;
}